<template>
  <div class="content">
    <Breadcrumb :data="nav" :is$t="true" />
    <main>
      <lawyer-card
        :btn-text="autoPrefix('6146d99')"
        :detail="detail"
        @jumpPage="jumpConsult(detail)"
      />
      <aside>
        <section class="card">
          <header>
            <span class="badge"></span>
            {{ $t("4906b41") }}
          </header>
          <article
            class="ql-editor"
            v-html="detail.briefIntroduction"
          ></article>
          <footer>
            <p class="title">{{ $t("003d5ff") }}</p>
            <ul class="tag-list">
              <li v-for="(item, key) in expertise" :key="key">{{ item }}</li>
            </ul>
            <p class="title">{{ $t("service.selectionList[0]") }}</p>
            <ul class="tag-list">
              <li v-for="(item, key) in serviceArea" :key="key">{{ item }}</li>
            </ul>
          </footer>
        </section>
      </aside>
    </main>
    <ConsultModal
      v-if="consultVisible"
      :id="consultId"
      :visible="consultVisible"
      :type="consultType"
      @close="consultVisible = false"
    />
  </div>
</template>

<script>
import Api from "@/api/communication/lawyer";
import LawyerCard from "./components/lawyerCard";
import Card from "@/components/Card";
import ConsultModal from "@/components/ConsultModal";
import Breadcrumb from "@/components/Breadcrumb";

const nav = [
  { name: "bae89f7", path: "/communication" },
  { name: "f4ebe52", path: "/communication/lawyer" },
];

const serverNav = [{ name: "574d006", path: "/service/lawyer" }];

export default {
  name: "LawyerDetail",
  components: {
    LawyerCard,
    Card,
    Breadcrumb,
    ConsultModal,
  },
  data() {
    return {
      Api,
      loading: false,
      detail: {},
      id: "",
      nav: [],
      expertise: [],
      serviceArea: [],
      consultVisible: false,
      consultId: undefined,
      consultType: "LAWYER",
    };
  },
  created() {
    this.getDetail();
  },
  methods: {
    jumpConsult(data) {
      const sorceType = this.$route.query.sorceType;
      if (sorceType === "lawer") {
        this.consultId = data.id;
        this.consultVisible = true;
        return;
      }
      this.$router.replace({
        path: `/communication/lawyer/consult?id=${this.id}&type=${this.type}`,
      });
    },
    getconsultType() {},
    autoPrefix(name) {
      let fun = "$t";
      if (this.abroad) {
        fun = "geEn";
      }
      return this[fun](name);
    },
    getDetail() {
      this.id = this.$route.query.id;
      this.type = this.$route.query.type || true;
      const sorceType = this.$route.query.sorceType;
      if (sorceType === "lawer") {
        if (this.type) {
          this.consultType = "GRAB_LAWYER";
        } else {
          this.consultType = "LAWYER";
        }
      }
      this.loading = true;
      Api.getDetail(this.id, this.type)
        .then((res) => {
          this.detail = res;
          this.expertise =
            res.businessExpertise && res.businessExpertise.split(",");
          this.serviceArea = res.serviceArea && res.serviceArea.split(",");
          const concatNav =
            this.$route.query.sorceType === "lawer" ? serverNav : nav;
          this.nav = [
            ...concatNav,
            {
              name: res.name,
              path: "",
            },
          ];
        })
        .catch(() => {})
        .then(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped lang="less">
.content {
  width: 1200px;
  margin: 20px auto;
}
main {
  display: flex;
  justify-content: space-between;
  padding: 0 0 20px;
}
aside {
  width: 920px;
  background: #fff;
  box-shadow: 0px 2px 22px 0px rgba(142, 142, 142, 0.18);
}
article {
  color: #333333;
  line-height: 21px;
  font-size: 14px;
}
.card {
  border-radius: 4px;
  //height: 100%;
  article {
    padding: 30px;
    border-bottom: 1px solid #eee;
  }
  header {
    padding: 20px 0;
    display: flex;
    align-items: center;
    padding-right: 20px;
    border-bottom: 1px solid #eee;
    font-size: 20px;
    font-weight: bold;
    color: #1890ff;
    .badge {
      margin-right: 30px;
      display: block;
      width: 3px;
      height: 17px;
      background: #1890ff;
      border-radius: 1.5px;
    }
  }
  footer {
    color: #333;
    padding: 30px;
    .title {
      font-size: 16px;
      padding-bottom: 20px;
    }
    .tag-list {
      display: flex;
      flex-wrap: wrap;
    }
    li {
      // height: 42px;
      line-height: 18px;
      background: #f7f8fa;
      border-radius: 4px;
      font-size: 12px;
      margin-right: 30px;
      padding: 8px 20px;
      margin-bottom: 20px;
    }
  }
}
</style>

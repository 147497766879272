<template>
  <div class="box">
    <p class="title">律师信息</p>
    <img :src="detail.logoUrl" alt="" class="avatar" />
    <p class="name">{{ detail.name }}</p>
    <dl>
      <dt>所属律所：</dt>
      <dd>{{ detail.practiceOrganization }}</dd>
    </dl>
    <dl>
      <dt>从业年限：</dt>
      <dd>{{ detail.yearsOfPractice }}</dd>
    </dl>
    <dl>
      <dt>联系电话：</dt>
      <dd>{{ detail.contactNumber }}</dd>
    </dl>
    <dl>
      <dt>律师执业证：</dt>
      <dd>{{ detail.licenseNumber }}</dd>
    </dl>
    <dl>
      <dt>邮箱：</dt>
      <dd>{{ detail.mail | textFilter }}</dd>
    </dl>
    <dl v-if="detail.foreignCountry">
      <dt>涉外国家：</dt>
      <dd>{{ detail.foreignCountry }}</dd>
    </dl>
    <dl>
      <dt>省份：</dt>
      <dd>{{ detail.province }}</dd>
    </dl>
    <div class="btn" @click="jumpPage" v-if="detail.consul">{{ btnText }}</div>
  </div>
</template>

<script>
export default {
  name: "LawyerDetail",
  props: {
    btnText: "",
    detail: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    jumpPage() {
      this.$emit("jumpPage");
    },
  },
};
</script>

<style scoped>
.box {
  width: 260px;
  height: 490px;
  background: #ffffff;
  box-shadow: 0px -1px 4px 0px rgb(0 0 0 / 8%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.title {
  width: 100%;
  font-size: 20px;
  color: #333;
  font-weight: bold;
  padding: 20px;
  text-indent: 20px;
  border-bottom: 1px solid #eee;
  box-sizing: border-box;
}
.avatar {
  width: 72px;
  height: 72px;
  border-radius: 50%;
  margin-top: 30px;
}
.name {
  margin: 20px auto;
  font-size: 16px;
  color: #333333;
  line-height: 24px;
  font-weight: bold;
}
dl {
  display: flex;
  width: 90%;
  margin: 0 auto;
  line-height: 14px;
  margin-bottom: 10px;
}
dt {
  width: 90px;
  color: #999999;
}
dd {
  flex: 1;
  color: #333333;
}
.btn {
  width: 220px;
  height: 34px;
  line-height: 34px;
  background: #1890ff;
  color: #fff;
  border-radius: 4px;
  margin-top: 30px;
  text-align: center;
  letter-spacing: 2px;
  margin-bottom: 30px;
}
</style>
